import { render, staticRenderFns } from "./z-certificate-table.vue?vue&type=template&id=6446db16&scoped=true&"
import script from "./z-certificate-table.vue?vue&type=script&lang=js&"
export * from "./z-certificate-table.vue?vue&type=script&lang=js&"
import style0 from "./z-certificate-table.vue?vue&type=style&index=0&id=6446db16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6446db16",
  null
  
)

export default component.exports