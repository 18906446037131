<template>
	<!-- TODO: Implement pagination -->
	<b-table
		v-if="tableItems && tableFields"
		:thead-class="tableHeader"  :fields="tableFields"  :items="tableItems"
		borderless responsive hover striped
	>
		<template #head()="data">
			<span>{{ data.label }}</span>
		</template>
		<template #cell(completed_path)="row">
			<div class="flex-shrink-0 flex-grow-0 thumbnail m-auto">
				<img :src="`${row.item.path.image.path}`">
			</div>
			<b v-if="row.item.path.name" class="ml-3 flex-grow-1">{{ row.item.path.name }}</b>
		</template>
		<template #cell(issued_at)="row">
			<span v-if="row.item.issued_at">{{formatDate(row.item.issued_at)}}</span>
		</template>
		<template #cell(download)="row" size="sm">
			<b-button class="download-btn inline-block" @click="emitItemToDownload(row.item._idx)">Stiahnuť</b-button>
		</template>
	</b-table>
</template>

<script>
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'

export default {
	props: {
		tableItems: {
			required: true,
			type: Array
		},
		tableFields: {
			required: true,
			type: Array
		},
		tableHeader: {
			required: false,
			type: String,
			default: ''
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		])
	},

	methods: {
		emitItemToDownload(id) {
			this.$emit('itemToDownload', id)
		},
		formatDate(v) {
			return moment(v).format('L')
		}
	}
}
</script>

<style lang="scss" scoped>
	.thumbnail {
		height: 48px;
		width: 76px;
		border-radius: 4px;
		overflow: hidden;

		img {
			width: 76px;
			object-fit: cover;
		}
	}

	svg {
		cursor: pointer;
	}

	a {
		text-decoration: none;
		color: #86B2CD;
		font-weight: lighter;
		font-family: Poppins;
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 21px;
		&:hover {
			color: white;
		}
	}

	::v-deep table {
		overflow-y: hidden;
		overflow-x: hidden;
		thead {
			tr {
				border-bottom: 1px solid #CADEEA;
				th {
					vertical-align: middle;
					span {
						color: #86B2CD;
						font-weight: lighter;
						font-family: Poppins;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 21px;
					}
				}
			}
		}
		tbody {
			tr {
				td {
					vertical-align: middle;
					color: #2B2E4A;
					button {
						color: #86B2CD !important;
						border-color: #86B2CD !important;
						font-size: small;
						font-weight: bold;
						min-width: 136px;
						min-height: 39px;
						padding: 0;
						&:hover {
							background-color: #FB2564;
							color: #FFFFFF !important;
							border-color: #FB2564 !important;
						}
					}
					a {
						text-decoration: none;
						color: #2B2E4A;
						font-weight: bold;
					}
				}
			}
		}
	}

	// TODO: Better responsiveness
	::v-deep td.completed-path {
		display: flex;
		align-items: center;
	}

	::v-deep .issued-at {
		width: 20%;
	}

	::v-deep .download{
		width: 20%;
	}

  // TODO: Fix button colors
	::v-deep .download-btn {
		width: 136px;
		background-color: #2B2E4A;
		color: white !important;
		border-radius: 100px;
		border-color: #2B2E4A !important;
	}

	button {
		&:hover {
			background-color: #FB2564;
			color: #FFFFFF !important;
			border-color: #FB2564 !important;
		}
	}
</style>